import "./SubmissionError.css";
import * as React from "react";
import { FunctionComponent } from "react";
import { Heading, Page } from "../components";

const SubmissionErrorPage: FunctionComponent = (): JSX.Element => {
  return (
    <Page title="Submission Error" layout="default">
      <div className="submission-error__hero">
        <div className="submission-error__content">
          <div className="submission-error__text">
            <Heading level={3}>
              There was an error processing your request.
            </Heading>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SubmissionErrorPage;
